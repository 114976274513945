import styled from 'styled-components';

const Flex = styled.div({
    display: 'flex',
});
const Grid = styled.div({
    display: 'grid',
});
const Col = styled(Flex)({
    flexDirection: 'column',
});
const Row = styled(Flex)({
    flexDirection: 'row',
    flexWrap: 'wrap',
});

export { Col, Flex, Grid, Row };
