import styled from 'styled-components';

const HEADER_TYPEFACE = 'neuton, radley';
const BODY_TYPEFACE = 'raleway, sans-serif';
const headerFont = {
    fontFamily: HEADER_TYPEFACE,
    fontWeight: 'bold',
};
const bodyFont = {
    FontFamily: BODY_TYPEFACE,
};
const Title = styled.div({
    fontSize: '3rem',
    lineHeight: '4rem',
    '@media (max-width: 600px)': {
        fontSize: '2.5rem',
        lineHeight: '3.5rem',
    },
    ...headerFont,
});
const H1 = styled.div({
    fontSize: '2rem',
    ...headerFont,
});
const H2 = styled.div({
    fontSize: '1.7rem',
    ...headerFont,
});
const H3 = styled.div({
    fontSize: '1.5rem',
    ...headerFont,
});
const H4 = styled.div({
    fontSize: '1.25rem',
    fontWeight: '400',
    ...bodyFont,
});
const P1 = styled.div({
    fontSize: '1rem',
    lineHeight: '1.3rem',
    ...bodyFont,
});
const P2 = styled.div({
    fontSize: '0.9rem',
    lineHeight: '1.3rem',
    ...bodyFont,
});
const P3 = styled.div({
    fontWeight: 200,
    fontSize: '0.7rem',
    lineHeight: '1rem',
    ...bodyFont,
});

export { BODY_TYPEFACE, H1, H2, H3, H4, HEADER_TYPEFACE, P1, P2, P3, Title };
