import {
  Color,
  Flex,
  P1,
  spacer,
  TransitionAnimation,
  TransitionInterval,
  vhSpace,
  vSpace,
  Warning,
} from '@babushkaui/core';
import { useEffect, useState } from 'react';
import { CombinedError } from 'urql';
import { ErrorVisibility } from '~/util/errorMessaging';

const errorColor = Color.Red.pair;

export const ErrorPopup = ({ error }: { error: CombinedError | null }) => {
  const [message, setMessage] = useState<string>();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (error) {
      const errors = error.graphQLErrors.filter((e) => e.extensions.visibility === ErrorVisibility.EXTERNAL);
      const messages = errors.map((e) => e.message);
      if (messages.length === 0) return;

      setMessage(messages[0]);
      setIsVisible(true);
    }

    const timeout = setTimeout(() => {
      setIsVisible(false);
    }, 5000);

    return () => clearTimeout(timeout);
  }, [error]);

  return (
    <Flex
      style={{
        position: 'fixed',
        display: isVisible ? 'flex' : 'hidden',
        flex: 1,
        zIndex: 1,
        bottom: 0,
        left: '50%',
        transform: `translateX(-50%) translateY(${isVisible ? 0 : '101%'})`,
        justifyContent: 'stretch',
        padding: vSpace(4),
        transition: `all ${TransitionInterval.STANDARD} ${TransitionAnimation.BOUNCE_IN_OUT}`,
      }}
    >
      <Flex
        style={{
          padding: vhSpace(2, 4),
          backgroundColor: errorColor.light,
          border: `1px solid ${errorColor.dark}`,
          borderRadius: spacer(2),
          color: errorColor.dark,
          gap: spacer(2),
          alignItems: 'center',
        }}
      >
        <Warning />
        <P1>{message}</P1>
      </Flex>
    </Flex>
  );
};
