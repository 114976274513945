import { BODY_TYPEFACE } from '@babushkaui/core';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { env } from 'process';
import React, { useMemo, useState } from 'react';
import { createGlobalStyle } from 'styled-components';
import { CombinedError, Provider } from 'urql';
import { PagePropsContext } from '~/components/contexts/PageProps';
import { ErrorPopup } from '~/components/error/ErrorPopup';
import { ImpersonatorTag } from '~/components/layout/ImpersonatorTag';
import graphQLClient from '~/webClients/graphql';

const UserLogging = React.lazy(() => import('~/webClients/webLogging/user'));

interface EnvProps {
  nodeEnv: string;
  env?: string;
  version?: string;
  webSocketUrl: string;
}

const GlobalStyle = createGlobalStyle({
  html: {
    fontSize: 16,
  },
  '@fontface': {
    fontDisplay: 'swap',
    fontFamily: BODY_TYPEFACE,
  },
  body: {
    fontFamily: BODY_TYPEFACE,
    fontSize: 16,
    margin: 0,
    backgroundColor: '#FFFCFB', //denomralized
  },
  input: {
    fontSize: 16,
  },
  select: {
    backgroundColor: 'unset',
  },
});

const MyApp = ({ Component, pageProps, ...props }: AppProps & EnvProps) => {
  const [error, setError] = useState<CombinedError | null>(null);

  const memoClient = useMemo(() => graphQLClient(props.webSocketUrl ?? '', setError), [props.webSocketUrl]);
  const environment = props?.env ?? 'local';

  return (
    <React.Fragment>
      <Head>
        <link rel='stylesheet' href='https://use.typekit.net/yzt1oze.css' />
      </Head>
      <Provider value={memoClient}>
        <UserLogging
          env={environment}
          version={props.version ?? 'local'}
          isEnabled={props?.nodeEnv === 'production'}
          service={`conductor`}
        />
        <GlobalStyle />
        <PagePropsContext.Provider value={pageProps}>
          <Component {...pageProps} />
          <ImpersonatorTag />
          <ErrorPopup error={error} />
        </PagePropsContext.Provider>
      </Provider>
    </React.Fragment>
  );
};

MyApp.getInitialProps = () => {
  return {
    nodeEnv: process.env.NODE_ENV,
    env: process.env.APP_ENV,
    version: process.env.BUILD,
    webSocketUrl: env.NEXT_PUBLIC_GRAPHQL_SUBSCRIPTIONS_URL,
  } satisfies EnvProps;
};

export default withLDProvider({
  clientSideID: env.LAUNCHDARKLY_CLIENT_ID ?? process.env.LAUNCHDARKLY_CLIENT_ID,
  options: {
    inspectors: [
      {
        type: 'flag-used',
        name: 'dd-rum-inspector',
        method: (key, detail) =>
          import('@datadog/browser-rum').then(({ datadogRum }) => datadogRum.addFeatureFlagEvaluation(key, detail.value)),
      },
    ],
  },
})(MyApp as any);
