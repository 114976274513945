/**
 * Standard transition intervals for working with animation times
 */
const TransitionInterval = {
    QUICK: '200ms',
    STANDARD: '400ms',
    LONG: '1000ms',
    DRAMATIC: '2400ms',
};
// UI Element Transitions
const Transitions = {
    UI: TransitionInterval.QUICK,
};
const TransitionAnimation = {
    BOUNCE: 'cubic-bezier(.1, 0, 0, 1.2)',
    BOUNCE_IN_OUT: 'cubic-bezier( .4, -.4, .6, 1.4 )',
};

export { TransitionAnimation, TransitionInterval, Transitions };
