import { ValueOf } from '@orchestrahealth/typedash';
import { GraphQLError } from 'graphql';

export const ErrorVisibility = {
  INTERNAL: 'INTERNAL',
  EXTERNAL: 'EXTERNAL',
} as const;
export type ErrorVisibility = ValueOf<typeof ErrorVisibility>;

/**
 * An error that is visible to the user.
 */
export class ExternalError extends GraphQLError {
  /**
   *
   * @param message Error message to display to the user
   */
  constructor(message: string) {
    super(message, {
      extensions: {
        visibility: ErrorVisibility.EXTERNAL,
        code: 'EXTERNAL_ERROR',
      },
    });
  }
}
