/* eslint-disable @next/next/no-html-link-for-pages */
import { Color, Cross, HoverIcon, hSpace, P1 } from '@babushkaui/core';
import { isNil } from '@orchestrahealth/typedash';
import { MouseEventHandler } from 'react';
import { useGetImpersonatorQuery } from '~/generated/graphql';

export const ImpersonatorTag = () => {
  const [{ data }] = useGetImpersonatorQuery();
  const { impersonator, user } = data ?? {};

  if (isNil(impersonator)) return null;

  return (
    <div
      suppressHydrationWarning={true}
      style={{
        position: 'fixed',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: hSpace(4),
        background: Color.Yellow.N400,
        left: 0,
        right: 0,
        bottom: 0,
        height: 24,
      }}
    >
      <span />
      <P1>Impersonating: {user?.fullName}</P1>
      <HoverIcon Icon={Cross} onClick={logout} />
    </div>
  );
};

const logout: MouseEventHandler<HTMLSpanElement> = async (e) => {
  e.stopPropagation();
  await fetch('/api/logout');
  window.location.assign('/surgery');
};
