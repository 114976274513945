import { wrap } from '../../../typedash/dist/index.js';

const Red = {
    N050: wrap('#fcf5f6'),
    N100: wrap('#FFCFD3'),
    N200: wrap('#FFA4A4'),
    N400: wrap('#A42A29'),
    N800: wrap('#802120'),
    A100: wrap('#A42A2933'),
    A400: wrap('#A42A2988'),
    A800: wrap('#A42A29CC'),
    pair: {
        light: wrap('#FFECEE'),
        dark: wrap('#A42A29'),
    },
};
const Green = {
    N050: wrap('#E8F5E9'),
    N100: wrap('#C8E6C9'),
    N200: wrap('#A5D6A7'),
    N400: wrap('#66BB6A'),
    N800: wrap('#1B5E20'),
    A100: wrap('#66BB6A33'),
    A400: wrap('#66BB6A88'),
    A800: wrap('#66BB6ACC'),
    pair: {
        light: wrap('#E8F5E9'),
        dark: wrap('#1B5E20'),
    },
};
const Blue = {
    N050: wrap('#e8eaf6'),
    N100: wrap('#bbdefb'),
    N200: wrap('#90caf9'),
    N400: wrap('#2196f3'),
    N800: wrap('#0d47a1'),
    A100: wrap('#2196f322'),
    A400: wrap('#2196f388'),
    A800: wrap('#2196f3cc'),
    pair: {
        light: wrap('#e3f2fd'),
        dark: wrap('#0d47a1'),
    },
};
const Purple = {
    N050: wrap('#EDE7F6'),
    N100: wrap('#D1C439'),
    N200: wrap('#B39DDB'),
    N400: wrap('#7E57C2'),
    N800: wrap('#673AB7'),
    A100: wrap('#7E57C222'),
    A400: wrap('#7E57C288'),
    A800: wrap('#7E57C2cc'),
    pair: {
        light: wrap('#EDE7F6'),
        dark: wrap('#673AB7'),
    },
};
const colorPalette = {
    Primary: Red,
    Yellow: {
        N050: wrap('#FFFDE7'),
        N100: wrap('#FFF9C4'),
        N200: wrap('#FFA4A4'),
        N400: wrap('#FFEE58'),
        N800: wrap('#d84315'),
        A100: wrap('#FFF9C433'),
        A400: wrap('#FFEE5888'),
        A800: wrap('#F9A825CC'),
        pair: {
            light: wrap('#FFFDE7'),
            dark: wrap('#d84315'),
        },
    },
    Red,
    Green,
    Blue,
    Purple,
    Neutral: {
        N050: wrap('#FAFAFA'),
        N100: wrap('#F5F5F5'),
        N200: wrap('#EEEEEE'),
        N400: wrap('#BDBDBD'),
        N800: wrap('#666666'),
        A100: wrap('#BDBDBD22'),
        A400: wrap('#BDBDBD88'),
        A800: wrap('#BDBDBDCC'),
        pair: {
            light: wrap('#EEEEEE'),
            dark: wrap('#666666'),
        },
    },
};
const standardColors = {
    Base: wrap('#FFFCFB'),
    ABase: wrap('#e5e2d6'),
};
const Color = {
    ...standardColors,
    ...colorPalette,
};
Color.Primary.A100;
const UNIT = 4;
const spacer = (count = 1) => UNIT * count;
const vSpace = (count = 1) => `${spacer(count)}px ${0} ${spacer(count)}px ${0}`;
const hSpace = (count = 1) => `0px ${spacer(count)}px 0px ${spacer(count)}px`;
const vhSpace = (vSpace = 1, hSpace = 1) => `${spacer(vSpace)}px ${spacer(hSpace)}px ${spacer(vSpace)}px ${spacer(hSpace)}px`;

export { Color, UNIT, hSpace, spacer, standardColors, vSpace, vhSpace };
