import { CombinedError, createClient, fetchExchange, subscriptionExchange } from '@urql/core';
import { devtoolsExchange } from '@urql/devtools';
import { cacheExchange } from '@urql/exchange-graphcache';
import { createClient as createWSClient, SubscribePayload } from 'graphql-ws';
import { env } from 'process';
import { Exchange } from 'urql';
import { pipe, tap } from 'wonka';
import WebSocket from 'ws';

const impl = env.NODE_ENV === 'test' ? WebSocket : undefined;

const wsClient = (url: string) =>
  createWSClient({
    url,
    webSocketImpl: impl,
  });

const wsSubscriptionsExchange = (url: string) =>
  subscriptionExchange({
    forwardSubscription: (operation) => {
      return {
        subscribe: (sink) => {
          const dispose = wsClient(url).subscribe(operation as SubscribePayload, sink);
          return {
            unsubscribe: dispose,
          };
        },
      };
    },
  });

const normalizedCache = cacheExchange();
const devExchanges = env.NODE_ENV === 'production' ? [] : [devtoolsExchange];

export const CLIENT_VERSION = 'client-version';

type ErrorAction = (error: CombinedError) => void;
type ErrorExchange = (action: ErrorAction) => Exchange;
export const constructErrorExchange: ErrorExchange =
  (action) =>
  ({ forward }) =>
  (ops$) => {
    return pipe(
      forward(ops$),
      tap(({ error }) => {
        if (error) {
          action(error);
        }
      }),
    );
  };

const localClient = (url: string, errorAction: (error: CombinedError) => void) => {
  const errorExchange = constructErrorExchange(errorAction);

  return createClient({
    url: '/api/graphql',
    fetchOptions: {
      headers: {
        [CLIENT_VERSION]: process.env.BUILD,
      } as any,
    },
    exchanges: devExchanges.concat([normalizedCache, errorExchange, fetchExchange, wsSubscriptionsExchange(url)]),
  });
};

export default localClient;
