import React__default from 'react';
import styled from 'styled-components';
import '../style/animation.js';
import { spacer } from '../style/color.js';

const HoverRoot = styled.div((props) => ({
    cursor: 'pointer',
    padding: spacer(),
    transition: '400ms',
    borderRadius: spacer(2),
    ':hover': {
        background: props.hoverColor ?? 'white',
    },
    ':active': {
        background: props.hoverColor ?? 'white',
        opacity: 0.5,
    },
}));
const HoverIcon = ({ Icon, onClick, style, color, length }) => {
    return (React__default.createElement(HoverRoot, { onClick: onClick, style: style, hoverColor: color },
        React__default.createElement(Icon, { width: length, height: length })));
};

export { HoverIcon, HoverRoot };
