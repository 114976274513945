import React__default from 'react';
import { Flex } from '../components/basic/wrappers.js';

const IconLength = {
    N050: 12,
    N100: 16,
    N200: 20,
    N400: 24,
    N800: 48,
};
const defaultIcon = { width: IconLength.N100, height: IconLength.N100 };
const SVG = ({ style, ...props }) => {
    const pprops = {
        width: props.width ?? IconLength.N100,
        height: props.height ?? props.width ?? IconLength.N100,
    };
    return (React__default.createElement(Flex, { style: { alignItems: 'center', justifyContent: 'center', ...style } },
        React__default.createElement("svg", { xmlns: 'http://www.w3.org/2000/svg', ...pprops, fill: 'currentColor', viewBox: '0 0 16 16' }, props.children)));
};

export { IconLength, SVG, defaultIcon };
